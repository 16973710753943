.checkbox-container {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 8px; /* Space between the checkbox and the label */
}
.boxLabel {
  font-family: Tajawal !important;
}
.boxClass {
  color: var(--gold) !important;
}
