.response-modal-container {
  width: 100%;
  font-size: 25px;
  display: flex;
}

.response-modal-message {
  padding-left: 10px;
  padding-top: 3px;
}
