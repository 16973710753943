.confirmation-container {
  padding: 20px;
}

.confirmation-msg {
  text-align: center;
  color: var(--grey);
  min-width: 400px;
  max-width: 600px;
}

.confirmation-actions {
  margin-top: 30px;
  text-align: center;
}

.save-btn {
  width: 25%;
  color: var(--white) !important;
  border-radius: 25px !important;
  background-color: red !important;
}
.close-btn {
  width: 25%;
  color: var(--grey) !important;
  border-radius: 25px !important;
}
