/* Table Container */
.table-main-container-displayUsers {
  height: 75%;
  padding: 0 3%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: auto;
  margin: 20px 0px;
}

.strong-margin {
  margin-left: 10px;
}
