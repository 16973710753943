.main-container-displayProperties {
  display: block;
  background: var(--basic);
  margin: 0;
  height: 100vh;
  width: 100%;
}
.main-container-displayProperties-homePageDisplay {
  display: block;
  background: var(--basic);
  margin: 20px 0px;
  height: auto;
  width: 100%;
}

/* Buttons Container */
.btns-main-container-displayProperties {
  display: flex;
  margin: auto;
  width: 80%;
  transition: 0.2s ease-in-out;
  height: 10%;
}

.add-btn-container-displayProperties {
  /* width: 20%; */
  height: 70%;
  margin: auto;
  transition: 0.2s ease-in-out;
}

.add-btn-displayProperties {
  width: 100%;
  font-size: 13px;
  height: 100%;
  border-radius: 24px;
  background-color: var(--mid-grey);
  border: none;
  color: var(--black);
  cursor: pointer;
  font-weight: medium;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.add-btn-displayProperties:focus {
  outline: none;
  box-shadow: none;
}

/* Table Container */
.table-main-container-displayProperties {
  padding: 0 3%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
