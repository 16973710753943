.input-container {
  width: 100%;
  position: relative;
}

.input-label {
  display: flex;
  color: var(--black);
  font-size: 22px;
  margin-bottom: 8px;
  justify-content: left;
  font-family: Gotham Rounded;
  font-weight: 500;
}

.input:focus {
  outline: none;
}
