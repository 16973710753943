.registration-body-container {
  display: block;
  width: 100%;
  height: auto;
  background: var(--grey-gradient);
  justify-content: center;
  align-content: center;
}

.registration-main-container {
  display: flex;
  width: 45%;
  margin: auto;
  vertical-align: middle;
  max-width: 100vw;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  transition: 0.2s ease-in-out;
}

.registration-logo {
  margin-top: 20px;
  background-color: var(--white);
  border-radius: 25px;
  height: 250px;
  align-self: center;
  margin-bottom: 30px;
  width: 225px;
}

.registration-card-container {
  background: #ffffff !important;
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  padding-bottom: 30px;
  border: 3px solid var(--mid-grey);
  margin-bottom: 40px;
}

.registration-labeltext-main-container {
  padding-top: 20px;
}

.registration-labeltext-container {
  margin: auto;
  width: 380px;
  height: 75px;
  margin-bottom: 17px;
}

.registration-button-container {
  margin: auto;
  width: 204px;
  height: 48px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: 0.2s ease-in-out;
}

.registration-already-user-container label,
a {
  margin-left: 10px;
  text-align: center;
  color: var(--black);
  font-size: 16px;
  font-family: Gotham Rounded;
}
.registration-already-user-container a {
  font-weight: bolder;
  text-underline-position: under;
}

.registration-already-user-container a:hover {
  color: var(--black);
}

.login {
  width: 100%;
  font-size: 13px;
  height: 50px;
  border-radius: 24px;
  background-color: var(--mid-grey);
  border: none;
  color: var(--black);
  cursor: pointer;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media only screen and (max-width: 1080px) {
  .registration-card-container {
    width: 95%;
    height: 85%;
    transition: 0.2s ease-in-out;
  }

  .registration-main-container {
    width: 90%;
    transition: 0.2s ease-in-out;
  }
  .registration-labeltext-container {
    width: 90%;
    margin-bottom: 17px;
  }
  .registration-labeltext-main-container {
    padding-top: 30px;
  }

  .registration-button-container {
    width: 60%;
    transition: 0.2s ease-in-out;
  }
}

@media only screen and (max-height: 900px) {
  .registration-body-container {
    height: calc(100% + 97px);
    padding-bottom: 50px;
    padding-top: 50px;
  }
}
