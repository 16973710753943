.login-body-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: var(--grey-gradient);
}

.login-main-container {
  display: flex;
  width: 45%;
  height: auto;
  margin: auto;
  vertical-align: middle;
  max-width: 100vw;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  transition: 0.2s ease-in-out;
  border-radius: 8px;
}

.login-logo {
  border-radius: 25px;
  height: 250px;
  align-self: center;
  margin-bottom: 30px;
  width: 225px;
}

.login-card-container {
  background-color: #ffffff !important;
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
  padding-bottom: 30px;
  border: 3px solid var(--mid-grey);
}

.login-labeltext-main-container {
  padding-top: 58px;
}

.login-labeltext-container {
  margin: auto;
  width: 60%;
  height: 75px;
  margin-bottom: 17px;
}
.login-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  width: 90%;
  height: 48px;
  margin-top: 40px;
  transition: 0.2s ease-in-out;
}

.login-button-container {
  width: 25%;
  height: 100%;
  transition: 0.2s ease-in-out;
}

.login-forget-password-container {
  margin-top: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.login-forget-password-container a {
  text-align: center;
  color: var(--black);
  font-size: 16px;
  font-family: Gotham Rounded;
  font-weight: bolder;
  text-decoration: none;
}

.login-forget-password-container a:hover {
  color: var(--black);
}

.login-buttons-container-arabic {
  direction: rtl;
}

.login {
  width: 100%;
  font-size: 13px;
  height: 50px;
  border-radius: 24px;
  background-color: var(--mid-grey);
  border: none;
  color: var(--black);
  cursor: pointer;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media only screen and (max-width: 1080px) {
  .login-card-container {
    width: 95%;
    transition: 0.2s ease-in-out;
  }

  .login-main-container {
    width: 90%;
    transition: 0.2s ease-in-out;
  }
  .login-labeltext-container {
    width: 90%;
    margin-bottom: 17px;
  }
  .login-labeltext-main-container {
    padding-top: 30px;
  }
  .login-button-container {
    width: 47%;
    transition: 0.2s ease-in-out;
  }
  .login-buttons-container,
  .login-buttons-container-arabic {
    width: 90%;
    transition: 0.2s ease-in-out;
  }
}

@media only screen and (max-height: 670px) {
  .login-body-container {
    height: calc(100% + 97px);
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

@media only screen and (max-height: 600px) {
  .registration-footer-container {
    height: 150px;
  }
}
