.search-input-container {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}

.input-label {
  display: flex;
  color: var(--black);
  font-size: 22px;
  justify-content: left;
  font-family: Gotham Rounded;
  font-weight: 500;
  margin-top: 20px;
}

.search-input {
  width: 100%;
  height: 40px;
  font-size: 16px;
  border: 3px solid var(--mid-grey);
  border-radius: 25px;
  color: var(--black);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.search-input:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 20px;
  height: 20px;
  color: var(--black);
}

.search-input:focus {
  outline: none;
  color: var(--black);
}

.search-input,
.search-input option {
  color: var(--black);
}

.search-input:invalid,
.search-input option[value=""] {
  color: #777777;
}
