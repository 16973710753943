.main-container-contactDetails {
  display: block;
  background: var(--basic);
  margin: 0;
  height: 100vh;
  width: 100%;
}

.section-main-container-contactDetails {
  height: auto;
}

.section-row-container-contactDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 50%;
  padding-top: 10px;
}

/* CONTACT INFO and BANK INFO SECTION COMMON */
.section-container-contactDetails {
  width: 100%;
}

.section-container-contactDetails-contactInfo {
  width: 95%;
  border-radius: 8px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  justify-content: space-between;
  height: fit-content;
}

/* Column Profile Picture */
.column-contactInfo-profile-picture {
  margin: auto;
  border-radius: 8px;
  align-items: center;
  width: 15%;
  height: fit-content;
}

.column-contactInfo-profile-picture img {
  width: 80%;
  height: 80%;
  display: block;
  justify-content: center;
  margin: auto;
  max-height: 300px;
  max-width: 300px;
}

/* Column Contact Info & Column Contact Address */
.column-contactInfo {
  padding-left: 10px;
  border-radius: 8px;
  margin: 5px;
  width: 40%;
  border: 3px solid var(--mid-grey);
}

.section-container-contactDetails-bankInfo {
  width: 95%;
  border-radius: 8px;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  flex-basis: 100%;
  flex: 1;
  height: fit-content;
}

.image-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.delete-image-btn-items {
  width: 18%;
  height: 53px;
  border-radius: 24px;
  background-color: var(--red);
  border: none;
  cursor: pointer;
  margin: 10px 0px 0px 15px;
}

/* COMMON  */
/* .label-text-container {}*/

@media only screen and (max-width: 1080px) {
  .section-container-contactDetails-contactInfo{
    flex-direction: column;
  }

  .section-container-contactDetails-bankInfo{
    flex-direction: column-reverse;
  }

  .column-contactInfo-profile-picture{
    width: 100%;
  }

  .column-contactInfo{
    width: 93%;
  }
}
