.main-container-addEditItems {
  display: block;
  background: var(--basic);
  margin: 0;
  height: 100vh;
  width: 100%;
}
/* Form Container */
.form-main-container-addEditItems {
  height: auto;
}

.form-column-addEditItems {
  width: 60%;
  margin-bottom: 20px;
  margin: auto;
  height: fit-content;
}

.form-card-text-container-addEditItems {
  text-align: center;
  font-size: x-large;
  font-family: "Courier New";
  font-weight: bold;
}

.form-card-input-container-addEditItems {
  width: 70%;
  display: block;
  margin: auto;
  border-style: solid;
  border-color: var(--dark-grey);
  border-radius: 20px;
  padding-bottom: 10px;
  transition: 0.2s ease-in-out;
  text-align: center;
  padding-top: 12px;
}

.labeltext-container-addEditItems {
  margin: auto;
  width: 70%;
}

.radio-container-addEditItems {
  width: 70%;
  margin: 35px auto;
  display: flex;
  justify-content: space-between;
}

/* Button container  */

.button-main-container-addEditItems {
  height: auto;
  padding-bottom: 20px;
}

.save-create-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  width: 40%;
  height: 48px;
  transition: 0.2s ease-in-out;
  justify-content: center;
}

.save-button-container,
.create-button-container {
  margin: 10px;
  width: 30%;
  height: 100%;
  transition: 0.2s ease-in-out;
}

.label {
  color: var(--black);
  font-size: 22px;
  justify-content: left;
  font-family: Gotham Rounded;
  font-weight: 500;
  /* margin-top: 20px; */
}

@media only screen and (max-width: 1260px) {
  .form-column-addEditItems {
    width: 90%;
    margin-bottom: 20px;
    height: fit-content;
  }

  .form-card-input-container-addEditItems {
    width: 90%;
    display: block;
    border-style: solid;
    border-color: var(--dark-grey);
    border-radius: 20px;
    padding-bottom: 10px;
    transition: 0.2s ease-in-out;
    text-align: center;
    padding-top: 12px;
  }
}
