.btn {
  background-color: var(--red);
  width: 100%;
  height: 100%;
  border-radius: 25px !important;
  border: 2px solid var(--mid-grey) !important;
  color: var(--black) !important;
  font-family: Tajawal !important;
}
.btn-login {
  width: 100%;
  font-size: 13px;
  height: 50px;
  border-radius: 24px;
  background-color: var(--mid-grey);
  border: none;
  color: var(--black);
  cursor: pointer;
  font-weight: medium;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.btn-login:focus {
  outline: none;
  box-shadow: none;
}
