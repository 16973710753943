body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --basic: #ffffff;
  --light-blue: #93c6ff;
  --mid-blue: #4063b8;
  --dark-blue: #183496;
  --black: #010101;
  --dark-grey: #a9a9a9;
  --mid-grey: #d6d6d6;
  --light-grey: #f4f4f4;
  --white: rgb(248, 248, 248);
  --red: #dd040a;
  --grey-gradient: linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
}
