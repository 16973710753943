.main-container-addEditProperties {
  display: block;
  background: var(--basic);
  margin: 0;
  height: auto;
  width: 100%;
}
/* Form Container */
.form-main-container-addEditProperties {
  height: auto;
}

.form-row-container-addEditProperties {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 50%;
  padding-top: 10px;
}

.form-column-container-addEditProperties {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.form-column-addEditProperties {
  margin-left: 20px;
  width: 95%;
  margin-bottom: 20px;
  height: fit-content;
}

.form-card-text-container-addEditProperties {
  text-align: center;
  font-size: x-large;
  font-family: "Courier New";
  font-weight: bold;
}

.form-card-input-container-addEditProperties {
  width: 70%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-style: solid;
  border-color: var(--dark-grey);
  border-radius: 20px;
  padding-bottom: 10px;
  transition: 0.2s ease-in-out;
  text-align: center;
  padding-top: 12px;
}

.labeltext-container-addEditProperties {
  margin: auto;
  width: 80%;
}

/* Button container  */

.button-main-container-addEditProperties {
  height: auto;
  padding-bottom: 20px;
}

.save-create-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  width: 40%;
  height: 48px;
  transition: 0.2s ease-in-out;
  justify-content: center;
}

.save-button-container,
.create-button-container {
  margin: 10px;
  width: 30%;
  height: 100%;
  transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 1260px) {
  .form-row-container-addEditProperties {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 50%;
    padding-top: 10px;
  }

  .form-card-input-container-addEditProperties {
    width: 90%;
    display: block;
    margin-right: auto;
    margin-left: auto;
    border-style: solid;
    border-color: var(--dark-grey);
    border-radius: 20px;
    padding-bottom: 10px;
    transition: 0.2s ease-in-out;
    text-align: center;
    padding-top: 12px;
  }

  .form-column-addEditProperties {
    margin-left: 10px;
    width: 95%;
    margin-bottom: 20px;
    height: fit-content;
  }

  .save-button-container,
  .create-button-container {
    margin: 10px;
    width: 65%;
    height: 100%;
    transition: 0.2s ease-in-out;
  }
}
