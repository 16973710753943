.main-container-propertyDetails {
  display: block;
  background: var(--basic);
  margin: 0;
  height: auto;
  width: 100%;
}

.section-main-container-propertyDetails {
  padding-left: 15%;
  height: auto;
}

.section-row-container-propertyDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  width: 100%;
  height: 20%;
  padding-top: 10px;
}

/* PROPERTY INFO and BANK INFO SECTION COMMON */
.section-container-propertyDetails {
  width: 100%;
}

.section-container-propertyDetails-propertyInfo {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  justify-content: space-between;
  height: fit-content;
}
/* Column property Info & Column property Address */
.column-propertyInfo {
  padding-left: 30px;
  border-radius: 8px;
  width: 100%;
}

.column-propertyInfo-single {
  padding-left: 10px;
  border-radius: 8px;
  margin: 5px;
  width: 80%;
  border: 3px solid var(--mid-grey);
}

.section-container-propertyDetails-bankInfo {
  padding-left: 20px;
  width: 80%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  height: fit-content;
  border: 3px solid var(--mid-grey);
}

/* Column Profile Picture */
.column-propertyInfo-profile-picture {
  display: flex;
  align-items: center;
  width: 60%;
  height: fit-content;
}

.column-propertyInfo-profile-picture img {
  display: block;
  width: 200px;
  height: 300px;
  object-fit: cover;
}

.labeltext-container-propertyNotes {
  width: 90%;
}

.project-notes-textfield .text-field-input {
  border: 1px solid #ccc; /* Example: a light grey border */
  height: 265px; /* Example: larger height for a textarea-like appearance */
  padding: 10px; /* Some padding inside the text field */
  font-size: 14px; /* Larger font size for readability */
}
.save-button-container {
  margin-left: 25px;
  width: 35%;
  height: 9%;
}
.edit-button-container {
  margin-left: 7px;
  margin-top: 5px;
  width: 25%;
  height: 9%;
}

.update-button-container {
  margin: 10px;
  width: 10%;
  transition: 0.2s ease-in-out;
}

#propertyImage {
  display: none;
}

.image-btn {
  width: 110px;
  font-size: 13px;
  height: 47px;
  border-radius: 24px;
  display: inline-block;
  padding: 2px 10px;
  background-color: var(--mid-grey);
  border: none;
  color: var(--black);
  text-align: center;
  cursor: pointer;
  font-weight: medium;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 10px 0;
}

.img-class {
  height: 400px;
  width: 300px;
  object-fit: cover;
}

.edit-image-container {
  display: flex;
  justify-content: center;
  width: 99%;
}

.no-images-found {
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
}

.delete-image-btn {
  width: 50px;
  height: 50px;
  border-radius: 24px;
  background-color: var(--red);
  border: none;
  cursor: pointer;
  margin: 10px 0;
}

.display-project-status-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.checkbox-section {
  width: 200px;
  padding-bottom: 10px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.propertyInfo-section {
  width: 250px;
  padding-bottom: 10px;
}

/* COMMON  */
/* .label-text-container {}*/

@media only screen and (max-width: 1080px) {
  .section-main-container-propertyDetails {
    padding-left: 10%;
    height: auto;
  }

  .column-propertyInfo-single,
  .column-propertyInfo {
    padding: 4px;
    border-radius: 8px;
    width: 85%;
  }

  .section-container-propertyDetails-bankInfo {
    padding: 4px;
    width: 85%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    height: fit-content;
    border: 3px solid var(--mid-grey);
  }

  .btn-login {
    width: 200%;
    font-size: 13px;
    height: 50px;
    border-radius: 24px;
    background-color: var(--mid-grey);
    border: none;
    color: var(--black);
    cursor: pointer;
    font-weight: medium;
    font-family: "Gotham Rounded";
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .column-propertyInfo-profile-picture {
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: fit-content;
    margin: 0 auto;
  }

  .column-propertyInfo-profile-picture img {
    border-radius: 16px;
    display: block;
    width: 200px;
    height: 290px;
    object-fit: cover;
  }
  .save-button-container {
    margin: auto;
    width: 35%;
    height: 9%;
  }
  .image-btn {
    width: auto;
    font-size: 13px;
    height: 50px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    background-color: var(--mid-grey);
    border: none;
    color: var(--black);
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    font-family: "Gotham Rounded";
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .column-propertyInfo {
    border-radius: 8px;
    width: 100%;
  }

  .labeltext-container-propertyNotes {
    width: 90%;
    margin: auto;
  }
  .edit-button-container {
    margin: 0 auto;
    width: 35%;
    height: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .update-button-container {
    width: 35%;
    height: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .no-images-found {
    font-size: 1.1em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
  }
}
