.nav-container {
  width: 100%;
  height: 60px;
  border-bottom: 3px solid var(--mid-grey);
}

.nav-hidden {
  display: none;
}
.nav-subcontainer {
  width: 70%;
  min-width: 800px;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}

.nav-img-container {
  align-self: center;
  float: right;
}

.nav-list-container {
  list-style: none;
  padding: 0px;
  margin-right: 100px;
  margin-top: 0px;
  height: 100%;
  display: flex;
}

.nav-list-tab {
  width: 170px;
  font-size: 20px;
  height: 100%;
  color: var(--grey);
  display: flex;
  cursor: pointer;
}

.nav-tab-active::after {
  content: "";
  position: absolute;
  color: var(--red);
  background: var(--black);
  height: 3px;
  top: 57px;
  width: 170px;
}

.nav-dropdown {
  position: relative;
  display: inline-block;
}

.nav-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.nav-dropdown-content-option {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.nav-dropdown-content-option:hover {
  background-color: #ddd;
  cursor: pointer;
}

.nav-dropdown:hover .nav-dropdown-content {
  display: block;
}

.nav-list-tab-text {
  margin: auto;
}

.nav-auth-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
}

.nav-button {
  width: 150%;
  font-size: 13px;
  border-radius: 24px;
  background-color: var(--mid-grey);
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  font-weight: medium;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
