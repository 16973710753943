.mobile-nav-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid var(--mid-grey);
  margin: 0px 10px;
}

.hide-mobile-nav {
    display: none;
}

.mobile-nav-button {
  width: 120px;
  height: 40px;
  margin: 5px 0px;
  font-size: 13px;
  border-radius: 24px;
  background-color: var(--mid-grey);
  border: none;
  color: var(--dark-blue);
  cursor: pointer;
  font-family: "Gotham Rounded";
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.burger-icon {
  width: 50px;
}

.mobile-nav-container ul{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    top: 53px;
    height: 50vh;
    width: 175px;
    z-index: 3;
    background-color: var(--mid-grey);
}

.mobile-nav-tab-active {
    width: 175px;
    border-bottom: solid 3px var(--black);
  }
