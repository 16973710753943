.main-container-homepage {
  display: block;
  background: var(--basic);
  margin: 0;
  height: 100vh;
  width: 100%;
}

.btns-main-container-homepage {
  display: flex;
  padding-top: 20px;
  margin: auto;
  justify-content: space-evenly;
  transition: 0.2s ease-in-out;
  width: 80%;
  height: 10%;
}

.add-btn-container-homepage {
  width: 20%;
  height: 50%;
  margin: auto;
  transition: 0.2s ease-in-out;
}

.add-btn-homepage {
  width: 100%;
  font-size: 13px;
  height: 100%;
  border-radius: 24px;
  background-color: var(--mid-grey);
  border: none;
  color: var(--black);
  cursor: pointer;
  font-weight: medium;
  font-family: "Gotham Rounded";
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.add-btn-homepage:focus {
  outline: none;
  box-shadow: none;
}

.table-main-container-homepage {
  display: block;
  height: auto;
  width: 100%;
  margin: 20px 0px;
}

.strong-margin {
  font-size: larger;
}

.red {
  color: red;
}

.green {
  color: green;
}

.blue {
  color: blue;
}
