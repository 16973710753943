.head:hover {
  cursor: pointer;
}

table {
  border-radius: 20px !important;
  border: none !important;
}

th {
  border-bottom: 3px solid rgba(128, 128, 128, 0.171) !important;
}

td {
  font-size: 10px !important;
  font-family: Tajawal !important;
  color: var(--grey) !important;
  border: none !important;
}

.MuiPaper-root {
  border-radius: 20px !important;
  font-family: Tajawal !important;
  padding-right: 2px;
  padding-left: 2px;
  border: 3px solid var(--mid-grey) !important;
}

.row:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.header-cell {
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  font-size: 12px !important;
  font-family: Tajawal !important;
  font-weight: bold !important;
  color: var(--grey) !important;
  background-color: #ffffff !important;
}

.header-cell:hover {
  filter: brightness(1.15);
  background-color: #a9a9a9 !important;
}

.header-cell-content {
  display: flex;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px;
}

.header-helper {
  width: 30px !important;
}

.table-search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.table-search-bar {
  border: 3px solid var(--mid-grey) !important;
  border-radius: 25px;
  padding: 5px 10px 0px 20px !important;
  width: calc(40% - 20px) !important;
}

.date-picker-container {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.MuiTableCell-root {
  padding: 3px !important;
}
