.main-container-itemDetails {
  display: block;
  background: var(--basic);
  margin: 0;
  height: 100vh;
  width: 100%;
}

.section-main-container-itemDetails {
  height: auto;
}

.section-row-container-itemDetails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 50%;
  padding-top: 10px;
}

/* ITEM INFO */
.section-container-itemDetails {
  width: 100%;
}

.section-container-itemDetails-itemInfo {
  width: 95%;
  border-radius: 8px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  justify-content: space-evenly;
  height: fit-content;
}

/* Column item Picture */
.column-itemInfo-item-picture {
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: fit-content;
}

.column-itemInfo-item-picture img {
  width: 80%;
  height: 80%;
  display: block;
  margin: auto;
  max-height: 400px;
  max-width: 400px;
}

/* Column item Info & Column item Address */
.column-itemInfo {
  padding-left: 10px;
  border-radius: 8px;
  margin-right: 25px;
  width: 60%;
  border: 3px solid var(--mid-grey);
}

.section-container-itemDetails-bankInfo {
  width: 95%;
  border-radius: 8px;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  flex-basis: 100%;
  flex: 1;
  height: fit-content;
}

.image-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.delete-image-btn-items {
  width: 18%;
  height: 53px;
  border-radius: 24px;
  background-color: var(--red);
  border: none;
  cursor: pointer;
  margin: 10px 0px 0px 15px;
}

/* COMMON  */
/* .label-text-container {}*/

@media only screen and (max-width: 1080px) {
  .section-container-itemDetails-itemInfo{
  flex-direction: column;
  }

  .column-itemInfo-item-picture{
    width: 100%;
  }

  .column-itemInfo {
    width: 95%;
  }
}
