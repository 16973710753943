.text-field-container {
  width: 100%;
}

.text-field-label {
  display: flex;
  color: var(--black);
  font-size: 22px;
  margin-bottom: 12px;
  justify-content: left;
  font-family: Gotham Rounded;
  font-weight: 500;
}

.text-field-input {
  padding: 0px;
  margin: 0px;
  border: none;
  width: calc(100% - 24px);
  height: 40px;
  font-size: 16px;
  border-radius: 24px;
  padding-left: 24px;
  font-weight: medium;
  border-style: solid;
  border-color: var(--mid-grey);
  margin-bottom: 12px;
}
.text-field-input:focus {
  outline: none;
}
