.input-container {
  width: 100%;
  position: relative;
}

.input-label {
  font-size: 18px;
  padding-bottom: 8px;
  display: block;
  text-transform: capitalize;
}

.input[type="text"] {
  width: calc(100% - 20px);
  background-color: var(--white);
  border-radius: 25px;
  border: none;
  padding: 0px;
  height: 46px;
  padding-left: 20px;
  font-size: 16px;
  border: 3px solid var(--mid-grey);
}
.textarea {
  width: calc(100% - 20px);
  background-color: var(--white);
  border-radius: 10px;
  border: none;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  font-size: 16px;
  border: 3px solid var(--mid-grey);
}

.input[type="number"] {
  width: calc(100% - 20px);
  background-color: var(--white);
  border-radius: 25px;
  border: none;
  padding: 0px;
  height: 46px;
  padding-left: 20px;
  font-size: 16px;
  border: 3px solid var(--mid-grey);
}

.input[type="date"],
.input[type="month"] {
  width: calc(100% - 20px);
  background-color: var(--white);
  border-radius: 25px;
  border: none;
  padding: 0px;
  height: 40px;
  padding-left: 20px;
  font-size: 16px;
  border: 3px solid var(--mid-grey);
}

.input:focus {
  outline: none;
}

.input-error {
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  color: var(--red);
  left: 20px;
}
